<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;border-radius: 20px;
    "
  >
    <v-card style="width:100%;">
      <!-- <v-card-title>External Warning</v-card-title> -->
      <v-card-text>
        <v-row align="center" justify="space-around">
          <v-col style="font-size:18px;font-weight:550;" cols="8">
            External
          </v-col>

          <v-col cols="4" align="center">
            <img src="" style="width:80%" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row align="center" justify="center"
          ><div v-if="isLoading">
            <img
              src="https://cdn.dribbble.com/users/563824/screenshots/3633228/untitled-5.gif"
              style="width:400px; height:300px;"
            />
          </div>
          <v-img
            :src="this.externalImgUrl"
            width="100%"
            v-show="isLoaded"
            @load="loaded"
          ></v-img
        ></v-row>
      </v-card-text>

      <v-card-actions
        style="
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 3%;
      "
      >
        <v-btn
          text
          :disabled="isLoading"
          color="white"
          style="width: 30%;font-size: 0.9em;background-color:#5eaaa8;"
          @click="sendButtonClick"
          >Send</v-btn
        >
        <v-btn
          text
          :disabled="isLoading"
          color="white"
          style="width: 30%;font-size: 0.9em;background-color:#e79685;"
          @click="cancelButtonClick"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ExternalWarning",
  props: {},
  data() {
    return {
      externalImgUrl: "",
      imgLogo: "",
      isLoaded: false,
      isLoading: true,
    };
  },
  created() {
    var vm = this;
    window.Office.onReady().then(function() {
      //console.log("ready");

      // eslint-disable-next-line no-undef
      window.Office.context.ui.addHandlerAsync(
        // eslint-disable-next-line no-undef
        window.Office.EventType.DialogParentMessageReceived,
        vm.dialogMsgReceived
      );
      //console.log("getdata");
      var messageObject = { messageType: "getdata" };
      var dataMsg = JSON.stringify(messageObject);

      // eslint-disable-next-line no-undef
      window.Office.context.ui.messageParent(dataMsg);
      vm.isLoaded = true;
    });
  },
  methods: {
    dialogMsgReceived(arg) {
      //console.log("data received");

      var data = JSON.parse(arg.message);
      this.externalImgUrl = data.externalImgUrl;
      this.imgLogo = data.logo;
    },
    sendButtonClick() {
      var messageObject = { messageType: "send" };
      var jsonMessage = JSON.stringify(messageObject);
      // eslint-disable-next-line no-undef
      window.Office.context.ui.messageParent(jsonMessage);
    },
    cancelButtonClick() {
      var messageObject = { messageType: "cancel" };
      var jsonMessage = JSON.stringify(messageObject);
      // eslint-disable-next-line no-undef
      window.Office.context.ui.messageParent(jsonMessage);
    },
    loaded() {
      this.isLoaded = true;
      this.isLoading = false;
    },
  },
};
</script>
