<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;border-radius: 20px;
    "
  >
    <v-card style="width:100%;">
      <!-- <v-card-title>Block Warning<v-spacer></v-spacer><v-img src="../assets/Enki_Logo.png" style="width:20px;"></v-img
          ></v-card-title> -->
      <v-card-text>
        <v-row align="center" justify="space-around">
          <v-col style="font-size:18px;font-weight:bold;" cols="8">
            Block Warning
          </v-col>
          <v-col cols="4" align="center">
            <img src="" style="width:80%" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row align="center" justify="center"
          ><div v-if="isLoading">
            <img
              src="https://i.pinimg.com/originals/45/12/4d/45124d126d0f0b6d8f5c4d635d466246.gif"
              style="width:400px; height:300px;"
            />
          </div>
          <v-img
            :src="this.blockImgUrl"
            width="100%"
            v-show="isLoaded"
            @load="loaded"
          ></v-img
        ></v-row>
      </v-card-text>
      <v-card-actions style="padding:0 100px 20px 100px;">
        <v-spacer></v-spacer>
        <v-btn
          text
          :disabled="isLoading"
          color="white"
          style="background-color:#5eaaa8;width: 30%;font-size:1em;"
          @click="cancelButtonClick"
          >OK</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "BlockedWarning",
  props: {},
  data() {
    return {
      blockImgUrl: "",
      imgLogo: "",
      isLoaded: false,
      isLoading: true,
    };
  },
  created() {
    var vm = this;
    window.Office.onReady().then(function() {
      //console.log("ready");

      // eslint-disable-next-line no-undef
      window.Office.context.ui.addHandlerAsync(
        // eslint-disable-next-line no-undef
        window.Office.EventType.DialogParentMessageReceived,
        vm.dialogMsgReceived
      );
      //console.log("getdata");
      var messageObject = { messageType: "getdata" };
      var dataMsg = JSON.stringify(messageObject);

      // eslint-disable-next-line no-undef
      window.Office.context.ui.messageParent(dataMsg);
      vm.isLoaded = true;
    });
  },
  methods: {
    dialogMsgReceived(arg) {
      //console.log("data received");

      var data = JSON.parse(arg.message);
      this.blockImgUrl = data.blockImgUrl;
      this.imgLogo = data.logo;
    },
    cancelButtonClick() {
      var messageObject = { messageType: "cancel" };
      var jsonMessage = JSON.stringify(messageObject);
      // eslint-disable-next-line no-undef
      window.Office.context.ui.messageParent(jsonMessage);
    },
    loaded() {
      this.isLoaded = true;
      this.isLoading = false;
    },
  },
};
</script>
