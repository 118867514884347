import Vue from 'vue';
import App from './App.vue';
import vuetify from "./plugins/vuetify";
import imagePreloader from 'vue-image-preloader'
import VueAppInsights from 'vue-application-insights'

Vue.config.productionTip = false;
Vue.use(imagePreloader)
Vue.use(VueAppInsights, {
  id: process.env.VUE_APP_ENKI_AI_ID
})
var vueObj = new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

if (window.Office) {
  window.Office.initialize = () => {
    vueObj

  };
  
}
else {
  vueObj
}

