<template>
  <v-app id="enkio365">
    <v-app-bar app color="#fff" dark height="70">
      <div class="d-flex align-center">
        <v-col cols="12">
          <!-- <v-toolbar-title
            ><img id="enkiLogo" src="" /></v-toolbar-title> -->
          <img id="enkiLogo" :src="this.imgLogo" style="width: 60px" />
        </v-col>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn class="mx-2" icon dark small color="#fff">
        <v-icon dark color="#0278ae">
          mdi-information-outline
        </v-icon>
      </v-btn> -->
      <v-dialog v-model="dialog" width="320">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            icon
            small
            color="#fff"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark color="#0278ae"> mdi-information-outline </v-icon>
          </v-btn>
        </template>

        <v-card style="text-align: center">
          <v-card-text style="padding: 30px 15px 25px 15px">
            <v-row justify="center"
              ><img
                src="https://img.icons8.com/cotton/100/000000/browser-window-1.png"
            /></v-row>
          </v-card-text>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>API Url</v-list-item-title>
              <v-list-item-subtitle>{{ this.apiUrl }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Host Name</v-list-item-title>
              <v-list-item-subtitle>{{
                this.hostPlatform
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Host Version</v-list-item-title>
              <v-list-item-subtitle>{{
                this.hostVersion
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>OWA View</v-list-item-title>
              <v-list-item-subtitle v-if="this.isOWA">Yes</v-list-item-subtitle>
              <v-list-item-subtitle v-else>No</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> Back </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <v-main>
      <div v-if="!promptType" style="
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;border-radius: 20px;
    ">
        
           <v-col cols="8">
            
         <div  class="text-center">
         <v-progress-circular
      indeterminate
      :size="50"
      color="primary"
    ></v-progress-circular>
    </div>
     </v-col> <v-col cols="8">
       <div  class="text-center">
    Loading...</div>
     </v-col>
      </div>
      <div v-if="promptType == 'External'"><ExternalWarning /></div>
      <div v-else-if="promptType == 'ExternalNontrusted'">
        <ExternalNontrusted />
      </div>
      <div v-else-if="promptType == 'Block'"><BlockedWarning /></div>
    </v-main>
  </v-app>
</template>

<script>
import ExternalWarning from "../src/components/ExternalWarning";
import BlockedWarning from "../src/components/BlockedWarning";
//import NonTrustedWarning from "../src/components/NonTrustedWarning";
import ExternalNontrusted from "../src/components/ExternalNonTrusted";
var data;
export default {
  name: "App",
  components: {
    ExternalWarning,
    BlockedWarning,
    //NonTrustedWarning,
    ExternalNontrusted,
  },
  data: () => ({
    promptType: null,
    dialog: false,
    notShow: false,
    imgLogo: "",
    hostPlatform: "",
    hostVersion: "",
    isOWA: false,
    apiUrl: "",
  }),
  created() {
    var vm = this;
    window.Office.initialize = function () {};
    window.Office.onReady().then(function () {
      //console.log("ready");

      // eslint-disable-next-line no-undef
      window.Office.context.ui.addHandlerAsync(
        // eslint-disable-next-line no-undef
        window.Office.EventType.DialogParentMessageReceived,
        vm.dialogMsgReceived,
        null,
        function (result) {
          var messageObject = { messageType: "getdata" };
          var dataMsg = JSON.stringify(messageObject);

          // eslint-disable-next-line no-undef
          window.Office.context.ui.messageParent(dataMsg);
        }
      );
      //console.log("getdata");

      vm.isLoaded = true;
    });
  },
  mounted() {},
  unmounted() {},
  methods: {
    dialogMsgReceived(arg) {
      //console.log("data received");

      data = JSON.parse(arg.message);
      this.imgLogo = data.logo;
      this.promptType = data.promptType;
      this.hostPlatform = data.hostPlatform;
      this.hostVersion = data.hostVersion;
      this.isOWA = data.isOWA;
      this.apiUrl = data.apiUrl;
      //console.log(this.apiUrl);
      //console.log(this.promptType);
    },
    sendButtonClick() {
      var messageObject = { messageType: "send" };
      var jsonMessage = JSON.stringify(messageObject);
      // eslint-disable-next-line no-undef
      window.Office.context.ui.messageParent(jsonMessage);
    },
    cancelButtonClick() {
      var messageObject = { messageType: "cancel" };
      var jsonMessage = JSON.stringify(messageObject);
      // eslint-disable-next-line no-undef
      window.Office.context.ui.messageParent(jsonMessage);
    },
  },
};
</script>
