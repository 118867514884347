<template>
  <div>
    <v-stepper v-model="e1" style="width:100%;">
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card style="width:100%;">
            <v-card-text>
              <v-row align="center" justify="space-around">
                <v-col style="font-size:18px;font-weight:550;" cols="8">
                  External Warning
                </v-col>

                <v-col cols="4" align="center">
                  <img src="" style="width:80%" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-row align="center" justify="center"
                ><div v-if="isLoading">
                  <img
                    src="https://cdn.dribbble.com/users/563824/screenshots/3633228/untitled-5.gif"
                    style="width:400px; height:300px;"
                  />
                </div>
                <v-img
                  :src="this.externalImgUrl"
                  width="80%"
                  v-show="isLoaded"
                  @load="loaded"
                ></v-img
              ></v-row>
            </v-card-text>
            <v-card-actions
              style="
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 3%;
      "
            >
              <v-btn
                text
                :disabled="isLoading"
                color="white"
                @click="e1 = 2"
                style="width: 30%;font-size: 0.9em;background-color:#5eaaa8;"
                >Continue</v-btn
              >
              <v-btn
                text
                :disabled="isLoading"
                color="white"
                @click="cancelButtonClick"
                style="width: 30%;font-size: 0.9em;background-color:#e79685;"
                >Cancel</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card-text>
            <v-row align="center" justify="space-around">
              <v-col style="font-size:18px;font-weight:550;" cols="8">
                Non-Trusted Warning
              </v-col>

              <v-col cols="4" align="center">
                <!-- <v-img
                  src="../assets/Enki_Logo.png"
                  alt="Logo"
                  width="40"
                ></v-img> -->
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <div
              class="v-card v-sheet theme--light"
              style="padding-bottom: 20px; margin: 0 10px"
            >
              <div class="v-card__title" style="font-size: 14px">
                This email included non-trusted recipients:
              </div>
              <div
                class="v-card__text"
                style="height: calc(50vh - 180px); overflow-y: auto"
              >
                <ul v-for="(item, index) in items" :key="index">
                  <li style="list-style-type: none; margin: 3px 0">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
          </v-card-text>
          <v-card-actions
            style="
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 3%;
      "
          >
            <v-btn
              text
              :disabled="this.items.length==0"
              color="white"
              @click="sendButtonClick"
              style="width: 30%;font-size: 0.9em;background-color:#5eaaa8;"
              >Send</v-btn
            >

            <v-btn
              text
              :disabled="this.items.length==0"
              color="white"
              @click="cancelButtonClick"
              style="width: 30%;font-size: 0.9em;background-color:#e79685;"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      e1: 1,
      items: [],
      externalImgUrl: "",
      imgLogo: "",
      isLoaded: false,
      isLoading: true,
    };
  },
  created() {
    var vm = this;
    window.Office.onReady().then(function() {
      //console.log("ready");

      // eslint-disable-next-line no-undef
      window.Office.context.ui.addHandlerAsync(
        // eslint-disable-next-line no-undef
        window.Office.EventType.DialogParentMessageReceived,
        vm.dialogMsgReceived
      );
      //console.log("getdata");
      var messageObject = { messageType: "getdata" };
      var dataMsg = JSON.stringify(messageObject);

      // eslint-disable-next-line no-undef
      window.Office.context.ui.messageParent(dataMsg);
      vm.isLoaded = true;
    });
  },
  mounted() {},
  unmounted() {},
  methods: {
    dialogMsgReceived(arg) {
      //console.log("data received");

      var data = JSON.parse(arg.message);
      this.externalImgUrl = data.externalImgUrl;
      this.imgLogo = data.imgLogo;
      this.items = data.IsNonTrustedDomain;
      //console.log(this.items);
    },
    sendButtonClick() {
      var messageObject = { messageType: "send" };
      var jsonMessage = JSON.stringify(messageObject);
      // eslint-disable-next-line no-undef
      window.Office.context.ui.messageParent(jsonMessage);
    },
    cancelButtonClick() {
      var messageObject = { messageType: "cancel" };
      var jsonMessage = JSON.stringify(messageObject);
      // eslint-disable-next-line no-undef
      window.Office.context.ui.messageParent(jsonMessage);
    },
    loaded() {
      this.isLoaded = true;
      this.isLoading = false;
    },
  },
};
</script>
